.hover {
    animation: hoverUpDown 3s ease-in-out infinite;
}

@keyframes hoverUpDown {
    0%, 100% {
        transform: translateY(0);
    }
    25% {
        transform: translateY(-20px);
    }
    50% {
        transform: translateY(0);
    }
    75% {
        transform: translateY(-10px);
    }
}

#hero-3d {
    height: 100%;
    width: 100%;

    model-viewer {
        width: 100%;
        height: 300px;
        --progress-bar-color: none;
        --progress-bar-height: 0px;
    }
}