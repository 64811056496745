body,
html,
#root,
.page{
  height: 100%;
}

.page {
  &.nslim {
    .template-header-bar {
      display: flex;
      justify-content: center;
      flex-direction: row;
      align-items: center;
      background-color: black;
      padding: 0.5rem 1rem;
      .logo {
        width: 100px;

        @media only screen and (max-width: 786px) {
            width: 30px;
        }
      }
    }
    .steps-counter-container {
      width: 100%;
    }
  }
}

@media only screen and (max-width: 786px) {
  .hidden-mobile {
    display: none;
  }
}